import { ninjaConfig } from '../config/ninja';
import { regionConfig } from '../config/region';
import { LQ_EVENTS, Trackers } from '../const';
import { cookieStorage, getCookieName } from '../cookies';
import { nativeHandlerFn } from './../native/handler';
import { getCustomParams } from './params';
import { checkIfDebugEligible, generateSession } from './session';
import { getTrackers } from './trackers';
import { getEventData, getHash, getHost, getInvite, getPageName, getReferrer, trackError } from './utils';

// need this way of structure, because we invoke track methods by property name `ninja[trackMethod]`
let ninja = {};

ninja.trackers = getTrackers();

export let currentTracker = 'start';

// Entry function - Parse the data in the dataLayer
ninja.checkParam = function () {
  /**
   * @param {String} fn - Function to invoke
   * @param {String} parameter - Function parameter
   * @param {Record<string, any>} data - user parameters
   */
  const c = (fn, parameter, data) => {
    if (ninjaConfig.isNative) {
      nativeHandlerFn(fn, parameter, data);
    } else {
      ninja[fn](parameter, data);
    }
  };

  for (const data of ninjaConfig.dataLayer) {
    if (typeof data === 'object' && undefined === data.processed) {
      for (const functionName of ninjaConfig.specialNames) {
        if (functionName !== 'processed' && data[functionName]) {
          // Queue the call to the function
          data.processed = true;
          window.trackingQueue.push(c(functionName, data[functionName], data));
        }
      }
    }
  }
};

// Push function - Tracking a page view
ninja.trackPage = function (pageName, eventData) {
  const params = getParams(pageName, null, eventData);

  for (const tracker of getTrackerList()) {
    try {
      ninja.trackers[tracker].trackPage(params);
    } catch (error) {
      trackError('JAVASCRIPT_ERROR', tracker, 'trackPage', error);
    }
  }
};

// Push function - Tracking an event
ninja.trackEvent = function (params, eventData) {
  let eventParams = params;

  // Fix for send trackEvent as string instead of Array
  if (typeof eventParams === 'string') {
    eventParams = [eventParams];
  }

  const ninjaParams = getParams(null, eventParams, eventData);

  for (const tracker of getTrackerList()) {
    try {
      ninja.trackers[tracker].trackEvent(ninjaParams);
    } catch (error) {
      trackError('JAVASCRIPT_ERROR', tracker, 'trackEvent', error);
    }
  }
};

// Push function - Tracking a link event
ninja.trackLinkEvent = function (eventParams, eventData) {
  const trackerList = getTrackerList();
  const params = getParams(null, eventParams, eventData);
  const errorCallback = params.customParams.linkErrorCallBack;
  delete params.customParams.linkErrorCallBack;

  try {
    if (ninjaConfig.linkCallBack !== null) {
      return;
    }
    if (undefined === params.customParams.linkCallBack || typeof params.customParams.linkCallBack !== 'function') {
      return;
    }
    ninjaConfig.linkCallBack = params.customParams.linkCallBack;
    ninjaConfig.linkEventName = eventParams;
    ninjaConfig.linkCount = 0;
    ninjaConfig.linkTotalCount = 0;
    delete params.customParams.linkCallBack;

    // First count how many
    ninjaConfig.linkTotalCount = trackerList.length;
    // Second trigger the track

    for (const tracker of getTrackerList()) {
      ninja.trackers[tracker].trackLinkEvent(params);
    }

    ninjaConfig.linkSetTimeout = setTimeout(() => {
      ninjaConfig.linkCount = ninjaConfig.linkTotalCount;
      ninjaConfig.linkCallBack.call(null, ninjaConfig.linkEventName);
      ninjaConfig.linkTotalCount = 0;
      ninjaConfig.linkCallBack = null;
    }, 1000);
  } catch (error) {
    trackError('JAVASCRIPT_ERROR', currentTracker, 'trackLinkEvent', error);

    if (typeof errorCallback === 'function') {
      errorCallback(null, error, params);
    }
  }
};

ninja.trackPerformanceEvent = function (eventName, eventData) {
  // these events do not need any previous data
  const params = getParams(null, [eventName], eventData, true);

  for (const tracker of getTrackerList()) {
    try {
      if (typeof ninja.trackers[tracker].trackPerformanceEvent === 'function') {
        ninja.trackers[tracker].trackPerformanceEvent(params);
      }
    } catch (error) {
      trackError('JAVASCRIPT_ERROR', tracker, 'trackPerformanceEvent', error);
    }
  }
};

// Push function - Cleanup dataLayer
ninja.cleanup = function (callBack) {
  let continueRunning = true;

  try {
    while (continueRunning) {
      continueRunning = false;
      for (let i = 0; i < ninjaConfig.dataLayer.length; i = i + 1) {
        // gtag uses array-like objects to set id and consent. Ignore such entries
        if (Array.isArray(ninjaConfig.dataLayer[i]) || 0 in ninjaConfig.dataLayer[i]) {
          continue;
        }

        if (
          undefined === ninjaConfig.dataLayer[i].event ||
          (ninjaConfig.dataLayer[i].event.slice(0, 4) !== 'gtm.' && undefined !== ninjaConfig.dataLayer[i]['gtm.uniqueEventId'])
        ) {
          if (
            typeof ninjaConfig.dataLayer[i] === 'object' &&
            undefined === ninjaConfig.dataLayer[i].call &&
            undefined === ninjaConfig.dataLayer[i].dynx_itemid && // Fix for RO
            undefined === ninjaConfig.dataLayer[i].dynx_totalvalue && // Fix for RO
            undefined === ninjaConfig.dataLayer[i].dynx_pagetype // Fix for RO
          ) {
            continueRunning = undefined === ninjaConfig.dataLayer[i].cleanup;
            ninjaConfig.dataLayer.splice(i, 1);
            i = length;
          }
        }
      }
    }
    if (typeof callBack === 'function') {
      callBack.call();
    }
  } catch (error) {
    trackError('JAVASCRIPT_ERROR', currentTracker, 'cleanup', error);
  }
};

// Push function - GTM event
ninja.event = function (eventName) {
  try {
    if (ninjaConfig.callBack) {
      ninjaConfig.callBack.call(ninja, eventName);
    }
  } catch (error) {
    trackError('JAVASCRIPT_ERROR', currentTracker, 'event', error);
  }
};

/**
 *  Get the trackers list
 * @returns {string[]}
 */
export function getTrackerList() {
  if (!Array.isArray(ninja.trackerList)) {
    ninja.trackerList = [Trackers.Hydra];
    const trackers = getTrackers();

    if (ninjaConfig.custom !== false && ninjaConfig.environment !== 'production') {
      if (ninjaConfig.custom) {
        for (const key of Object.keys(ninjaConfig.custom)) {
          if (trackers[key]?.trackPage) {
            ninja.trackerList.push(key);
          }
        }
      }
    } else {
      // For Laquesis functionality
      if (getPluginList().includes(Trackers.Laquesis)) {
        // insert at position 1 - just after hydra and before all 3rd party trackers
        ninja.trackerList.push(Trackers.Laquesis);
      }

      const enabledTrackers = regionConfig.custom[ninjaConfig.siteUrl].trackers ?? regionConfig.trackers;
      const allTrackers = Object.values(Trackers);

      for (const tracker of enabledTrackers) {
        if (allTrackers.includes(tracker)) {
          ninja.trackerList.push(tracker);
        }
      }
    }
  }

  return ninja.trackerList;
}

// Get the plugins list
export function getPluginList() {
  if (!ninja.pluginList) {
    if (ninjaConfig.plugins && ninjaConfig.environment !== 'production') {
      ninja.pluginList = [];
      for (const plugin of ninjaConfig.plugins) {
        ninja.pluginList.push(plugin);
      }
    } else {
      if (regionConfig.custom[ninjaConfig.siteUrl].plugins) {
        ninja.pluginList = [].concat(regionConfig.custom[ninjaConfig.siteUrl].plugins);
      } else {
        ninja.pluginList = [].concat(regionConfig.plugins);
      }
    }
  }
  return ninja.pluginList;
}

/**
 * Manage the session cookie and update counters
 * @param {boolean} readOnly Do not update session count
 */
export function getSessionParams(readOnly = false) {
  let date;
  let now;
  let session;
  let sessionLong;
  let sessionCount;
  let sessionCountLong;
  let sessionExpired;
  let sessionExtra;
  let sessionValues;
  let cookieName;
  let cookieValue;
  let noCookie = false;
  let sessionParams;
  let isSessionChange = false;

  let debugInfo = [];

  debugInfo.push('Retrieving session params');

  try {
    if (navigator.cookieEnabled) {
      debugInfo.push('Cookies enabled. Checking onap cookie');
      date = new Date();
      now = Math.round(date.getTime() / 1000);
      cookieName = getCookieName('onap');
      sessionValues = (cookieStorage.get(cookieName) || '').match(/([a-z0-9]+)-([0-9]+)-([a-z0-9]+)-([0-9]+)-([0-9]+)-?(.*)?/);
      debugInfo.push('Cookie value (onap): ' + sessionValues);
      if (sessionValues && sessionValues.length > 0) {
        sessionLong = sessionValues[1];
        sessionCountLong = parseInt(sessionValues[2], 10);
        session = sessionValues[3];
        sessionCount = parseInt(sessionValues[4], 10);
        sessionExpired = sessionValues[5];
        sessionExtra = sessionValues[6] || null;

        if (sessionExpired - now > 0) {
          sessionCount = sessionCount + 1;
        } else {
          debugInfo.push('Session expired. Generating a new session');
          sessionCountLong = sessionCountLong + 1;
          session = generateSession();
          sessionCount = 1;
        }
      } else {
        // Check if there is a lqonap cookie
        sessionValues = cookieStorage.get(getCookieName('lqonap'));
        debugInfo.push('Cookie value (lqonap): ' + sessionValues);
        if (sessionValues && sessionValues.length > 0) {
          // Use the laquesis session_long
          sessionLong = sessionValues;
          sessionCountLong = 1;
          session = sessionLong;
          sessionCount = 1;
          sessionExtra = null;
          ninjaConfig.cookieFromLq = true;
        } else {
          debugInfo.push('Cookie not found. Generating a new sessionLong');
          // New user, create new session_long
          sessionLong = generateSession();
          sessionCountLong = 1;
          session = sessionLong;
          sessionCount = 1;
          sessionExtra = null;
        }
      }
      sessionExpired = now + 1800;

      // In readonly mode we only want to verify/create the cookie. The next actual usage will increment the counter
      if (readOnly) {
        sessionCount -= 1;
      }

      cookieValue = sessionLong + '-' + sessionCountLong + '-' + session + '-' + sessionCount + '-' + sessionExpired;
      if (sessionExtra) {
        cookieValue = cookieValue + '-' + sessionExtra;
      }
      cookieValue = cookieValue.replace(/[^\w\-\=]/g, '');

      cookieStorage.set(cookieName, cookieValue, {
        expires: 360,
        path: '/',
        domain: ninjaConfig.cookieDomain,
      });
    } else {
      debugInfo.push('Cookies disabled. Generating a new sessionLong');
      sessionLong = generateSession();
      sessionCountLong = 1;
      session = sessionLong;
      sessionCount = 1;
      noCookie = true;
    }

    isSessionChange = ninjaConfig.currentSession !== null && ninjaConfig.currentSession !== session;

    // if session has changed and it's not the first one, trigger callback
    if (isSessionChange && typeof window.ninjaSessionChangedCallback === 'function') {
      window.ninjaSessionChangedCallback.apply(null, [session, ninjaConfig.currentSession]);
    }
  } catch (error) {
    debugInfo.push('Retrieving cookie data failed: ' + error.message + '. Generating a new sessionLong');
    sessionLong = generateSession();
    sessionCountLong = 1;
    session = sessionLong;
    sessionCount = 1;
    noCookie = true;
  } finally {
    // trackDebugInfo(debugInfo.join('\n'));
  }

  sessionParams = {
    sessionLong: sessionLong,
    session: session,
    sessionCountLong: sessionCountLong,
    sessionCount: sessionCount,
  };

  if (noCookie) {
    sessionParams.noCookie = noCookie;
  }

  ninjaConfig.currentSessionLong = sessionLong;
  ninjaConfig.currentSession = session;

  // update exposed window props only when session changes
  if (isSessionChange) {
    // exposeWindowObject();
  }

  return sessionParams;
}

/**
 * Checks if this is special laquesis-triggered event
 * @param {Record<string, unknown> | null} [eventData]
 */
export function isLaquesisEvent(eventData) {
  if (Array.isArray(eventData?.trackEvent) && Object.values(LQ_EVENTS).includes(eventData.trackEvent[0])) {
    return true;
  }

  return false;
}

// Collect all the data available
export function getParams(pageName, eventParams, eventData, disablePropertyPropagation = ninjaConfig.disablePropertyPropagation) {
  const noPropagationForLaquesisEvents = isLaquesisEvent(eventData);

  return {
    invite: getInvite(),
    host: getHost(),
    hash: getHash(),
    referrer: getReferrer(),
    pageName: getPageName(pageName),
    eventData: getEventData(eventParams),
    //  TRACK-2781 - laquesis events do not need custom params from previous events.
    customParams: getCustomParams(eventData, disablePropertyPropagation || noPropagationForLaquesisEvents),
    sessionParams: getSessionParams(),
  };
}

/**
 * Internal function - Track debug information into hydra error stream
 * @param {string} info - the text to track
 * @param {boolean} [force=false] - force the message to be logged, ignoring the configuration
 */
export function trackDebugInfo(info, force) {
  if (force || checkIfDebugEligible(ninjaConfig.currentSession)) {
    return trackError('DEBUG_INFO', undefined, undefined, info);
  }

  return undefined;
}

// export functions
export let trackEvent = ninja.trackEvent;
export let trackPage = ninja.trackPage;
export let checkParam = ninja.checkParam;
