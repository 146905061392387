// https://naspersclassifieds.atlassian.net/wiki/spaces/DATA/pages/57720210575/Ninja+Android#WebViews
import { stripProperties } from '../utils/helpers';

/**
 * @params {string} name
 * @param {Record<string, unknown>} [extraData]
 */
export const trackPage = (name, extraData = {}) => {
  const props = stripProperties(extraData, ['trackPage']);
  // Android expects params to be JSON-encoded strings
  return window?.NinjaAndroid?.trackView(name, JSON.stringify(props));
};

/**
 * @params {string} name
 * @param {Record<string, unknown>} [extraData]
 */
export const trackEvent = (name, extraData = {}) => {
  const props = stripProperties(extraData, ['trackEvent']);
  // Android expects params to be JSON-encoded strings
  return window?.NinjaAndroid?.trackEvent(name, JSON.stringify(props));
};

export const trackEventDelayed = trackEvent;

// Laquesis
/**
 * @param {string} name
 */
export const getLaquesisVariant = name => window?.LaquesisAndroid?.getVariant(name);

/**
 * @param {string} name
 */
export const isFeatureEnabled = name => window?.LaquesisAndroid?.isFlagEnabled(name) ?? false;

export const flushDelayed = () => {};
