import { hydraConfig } from '../config/hydra';
import { ninjaConfig } from '../config/ninja';

/**
 * Only some regions/countries are required to comply to GDPR.
 * When in native mode, ignore the consent - the native app will handle it
 * @returns {boolean}
 */
export function isEprivacyStorageRequired() {
  if (ninjaConfig.isNative) {
    return false;
  }

  return !!hydraConfig.eprivacy_scope;
}
