import { hydraConfig } from '../../config/hydra';
import { ninjaConfig } from '../../config/ninja';
import { regionConfig } from '../../config/region';
import { HYDRA_HOST } from '../../const';
import { eucex, getCurrentPath, getProtocol, objectToQueryString } from '../../utils';
import { randomFactor } from '../session';
import { getHost } from './general';

export function trackWithBeacon(url, params) {
  if (ninjaConfig.isNative) {
    console.log('Try to track while in Native env');
    return Promise.resolve();
  }

  // hydra POST requires specific body structure
  const hydraPostBody = window.JSON.stringify({
    tracks: [objectToQueryString(params)],
  });

  if (navigator.sendBeacon) {
    navigator.sendBeacon(url, hydraPostBody);
    return Promise.resolve();
  } else {
    return window.fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: hydraPostBody,
      keepalive: true,
    });
  }
}

// Track error
export function trackError(errorName, tracker, method, error) {
  if (ninjaConfig.isNative) {
    console.log('Try to track while in Native env');
    return;
  }

  const url = getProtocol() + HYDRA_HOST + hydraConfig.error_path;
  const props = {};
  let info;

  if (error) {
    if (error.description) {
      info = error.description;
    } else if (error.message) {
      info = error.message;
    } else {
      info = error;
    }
  } else {
    info = error;
  }

  // Path

  // Properties
  props.eN = eucex(errorName);
  props.sl = ninjaConfig.currentSessionLong;

  if (ninjaConfig.currentSession) {
    props.s = ninjaConfig.currentSession;
  } else {
    props.s = '00000000000' + 'x' + randomFactor;
  }
  if (tracker) props.tracker = eucex(tracker);
  if (method) props.method = eucex(method);
  props.info = eucex(info);

  // Config values, countries and regions
  props.cC = hydraConfig.params.cC;
  props.bR = hydraConfig.params.bR;
  props.cH = ninjaConfig.platform;

  // Matrix Version
  if (undefined !== regionConfig.version) {
    props.mv = regionConfig.version;
  }

  // Current page
  props.cP = eucex(window.location.href);

  // Environment
  if (ninjaConfig.environment !== 'production') {
    props.env = 'dev';
  }

  // Timestamp
  props.t = new Date().getTime();

  trackWithBeacon(url, props);
}

/**
 * Track web-vital metric to Hydra
 * The existance of performance metrics already guarantees modern browsers.
 * We can use modern functions here
 *
 * @param {PerformanceMetric} metric
 * @param {string} pageName
 * @param {(params: Record<string, any>) =>  Record<string, any>} applyPlatformFn
 * @returns
 */
export function trackWebVital(metric, pageName, applyPlatformFn) {
  if (ninjaConfig.isNative) {
    console.log('Try to track while in Native env');
    return;
  }

  const url = getProtocol() + HYDRA_HOST + hydraConfig.vitals_path;
  let props = {};

  if (!window.JSON || !(navigator.sendBeacon || window.fetch)) {
    return;
  }

  // Properties

  // No mapping is needed. This events/props will later be moved to be reserved words for ninja
  props.eN = 'web_vital';
  props.web_vital_name = metric.name;
  props.web_vital_value = metric.value;
  props.web_vital_rating = metric.rating;
  props.web_vital_page = pageName;

  props.sl = ninjaConfig.currentSessionLong;
  if (ninjaConfig.currentSession) {
    props.s = ninjaConfig.currentSession;
  } else {
    props.s = '00000000000' + 'x' + randomFactor;
  }

  // Config values, countries and regions
  props.cC = hydraConfig.params.cC;
  props.bR = hydraConfig.params.bR;

  // Matrix Version
  if (regionConfig.version) {
    props.mv = regionConfig.version;
  }

  // Current page
  props.cP = getCurrentPath();

  // Environment
  if (ninjaConfig.environment !== 'production') {
    props.env = 'dev';
  }

  // Timestamp
  props.t = new Date().getTime();

  // Host
  const host = getHost();
  if (host) {
    props.host = host;
  }

  props = applyPlatformFn(props);

  trackWithBeacon(url, props);
}
