import { ninjaConfig } from './config/ninja';
import { flushDelayed, trackEventDelayed, trackPageDelayed } from './core/delayedTracking';
import { startNinja } from './startup';

/** Start ninja load */
startNinja();

/**
 * Track ninja event
 * @param {String | String[]} eventName Event to track
 * @param {Record<string, any>} params
 */
function trackEvent(eventName, params) {
  if (eventName && ninjaConfig.dataLayer) {
    const trackParams = Object.assign({}, params || {}, {
      trackEvent: Array.isArray(eventName) ? eventName : [eventName],
    });
    ninjaConfig.dataLayer.push(trackParams);
  }
}

/**
 * Track page
 * @param {String} name page name
 * @param {Record<string, any>} params
 */
function trackPage(name, params) {
  const trackParams = Object.assign({}, params || {}, { trackPage: name });

  ninjaConfig.dataLayer.push(trackParams);
}

/**
 * Track page
 * @param {String} name page name
 * @param {Record<string, any>} params
 */
function trackPerformanceEvent(name, params) {
  var trackParams = Object.assign({}, params || {}, { trackPerformanceEvent: name });

  ninjaConfig.dataLayer.push(trackParams);
}

export default {
  trackEvent,
  trackPage,
  trackPerformanceEvent,

  trackEventDelayed,
  trackPageDelayed,
  flushDelayed,
};
