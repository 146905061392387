import { TEST_DIVIDER, VALUE_DIVIDER, VARIANT_DIVIDER } from '../../const';

/**
 * Read and parse the test cookie
 * @param {string} cookie
 * @param {(name: string, variant: string) => void} callback
 * @returns {Record<string, string>}
 */
export function parseLaquesisCookie(cookie = '', callback = () => {}) {
  // Fix for some servers that quotes the cookie when contains @ :: Use value without quotes
  const cookieValue = cookie?.replace(/^"(.+)"$/, '$1') ?? '';
  const activeTests = {};

  if (cookieValue) {
    for (const value of cookieValue.split(TEST_DIVIDER)) {
      if (!value) {
        continue;
      }
      const nameValue = value.split(VARIANT_DIVIDER);

      if (nameValue.length === 2) {
        const valueFlag = nameValue[1].split(VALUE_DIVIDER);
        if (valueFlag.length === 2) {
          // Read the set-cookie from the server
          if (valueFlag[1] === 't') {
            if (typeof callback === 'function') {
              callback(nameValue[0], valueFlag[0]);
            }

            if (!(nameValue[0] in activeTests)) {
              activeTests[nameValue[0]] = valueFlag[0];
            }
          }
        } else {
          // Read the normal cookie
          if (!(nameValue[0] in activeTests)) {
            activeTests[nameValue[0]] = valueFlag[0];
          }
        }
      }
    }
  }

  return activeTests;
}

/**
 * parse the flags cookie
 * @param {string} cookieValue
 * @returns {Record<string, number>}
 */
export function parseLaquesisFfCookie(cookieValue = '') {
  const activeFlags = {};

  if (cookieValue) {
    for (const flag of cookieValue.split(TEST_DIVIDER)) {
      if (flag && !(flag in activeFlags)) {
        activeFlags[flag] = 1;
      }
    }
  }

  return activeFlags;
}
