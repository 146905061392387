import { getNinjaConfig } from '../config/ninja';
import { Channel } from '../const';
import * as androidBridge from './androidBridge';
import * as iosBridge from './iosBridge';

/**
 * Checks if the native handlers exist in window and returns the appropriate function
 * @param {string} fn
 * @returns
 */
export function getNativeFn(fn) {
  let fnToInvoke;

  if (getNinjaConfig().platform === Channel.Android) {
    fnToInvoke = androidBridge[fn];
  } else if (getNinjaConfig().platform === Channel.iOS) {
    fnToInvoke = iosBridge[fn];
  }

  if (typeof fnToInvoke !== 'function') {
    throw new Error(`Function ${fn} not defined in native bridge!`);
  }

  return fnToInvoke;
}

/**
 *
 * @param {'trackView' | 'trackEvent' | 'getVariant' | 'isFeatureEnabled' | 'trackEventDelayed' | 'flushDelayed'} fn
 * @param  {...any} args
 * @returns
 */
export function nativeHandlerFn(fn, ...args) {
  const fnToInvoke = getNativeFn(fn);

  return fnToInvoke(...args);
}
