// https://naspersclassifieds.atlassian.net/wiki/spaces/DATA/pages/57720538471/Ninja+iOS#Webviews-(v1.2.5%2B)
import { parseLaquesisCookie, parseLaquesisFfCookie } from '../trackers/laquesis/cookieParser';
import { stripProperties } from '../utils/helpers';

/**
 * @params {string} name
 * @param {Record<string, unknown>} [extraData]
 */
export const trackPage = (name, extraData = {}) => {
  const props = stripProperties(extraData, ['trackPage']);
  return window?.webkit?.messageHandlers?.trackView.postMessage({
    name,
    params: props,
  });
};

/**
 * @params {string} name
 * @param {Record<string, unknown>} [extraData]
 */
export const trackEvent = (name, extraData = {}) => {
  const props = stripProperties(extraData, ['trackEvent']);
  return window?.webkit?.messageHandlers?.trackEvent.postMessage({
    name,
    params: props,
  });
};

export const trackEventDelayed = trackEvent;

// Laquesis
// @see https://git.naspersclassifieds.com/global-data-services/collection/ninja/ios-ninja/-/merge_requests/490
/**
 * iOS brdge methods are async by default. We post the message to trigger any native logic,
 * but use the exposed experiments from iOS to retrieve the real value syncronously.
 * @param {string} name
 * @returns {string | undefined}
 */
export const getLaquesisVariant = name => {
  window?.webkit?.messageHandlers?.getVariant.postMessage({
    name,
  });

  if (window.LaquesisIOS?.experiments) {
    const active = parseLaquesisCookie(window.LaquesisIOS.experiments);

    return active?.[name];
  }

  return null;
};

/**
 * iOS brdge methods are async by default. We post the message to trigger any native logic,
 * but use the exposed flags from iOS to retrieve the real value syncronously.
 * @param {string} name
 * @returns {boolean}
 */
export const isFeatureEnabled = name => {
  window?.webkit?.messageHandlers?.isFlagEnabled.postMessage({
    name,
  });

  if (window.LaquesisIOS?.flags) {
    const active = parseLaquesisFfCookie(window.LaquesisIOS.flags);

    return !!active?.[name];
  }

  return false;
};

export const flushDelayed = () => {};
