import { Channel } from '../const';
import { configTracking } from './configTracking';

let cookieDomain;
const environment = configTracking?.environment ?? 'production';

if (environment === 'production') {
  if (configTracking?.siteUrl) {
    cookieDomain = configTracking.siteUrl.match(/^[\w\d\-]+\.(.*)/);
    if (cookieDomain) {
      cookieDomain = cookieDomain[1];
    } else {
      cookieDomain = configTracking.siteUrl;
    }
  }
} else {
  cookieDomain = window.location.hostname || null;
}

window.dataLayer = window.dataLayer || [];
window.dataLayerDelayed = window.dataLayerDelayed || [];

function defaultValue(key, valueOrFn) {
  if (configTracking && key in configTracking) {
    return configTracking[key];
  }

  if (typeof valueOrFn === 'function') {
    return valueOrFn(configTracking, key);
  }

  return valueOrFn;
}

export const ninjaConfig = {
  /**
   * @type {String}
   */
  environment: environment,
  /**
   * @type {String}
   */
  cookieDomain: cookieDomain,
  /**
   * @type {String[]}
   */
  specialNames: ['trackPage', 'trackEvent', 'trackLinkEvent', 'trackPerformanceEvent', 'cleanup', 'event', 'processed'],
  /**
   * @type {String}
   */
  siteUrl: defaultValue('siteUrl', ''),
  /**
   * @type {function}
   */
  callBack: defaultValue('backendCallBack', null),

  /**
   * If the value is `a` or `i`, it means we are in a webview and Ninja should forward all
   * events to the parent application via a custom bridge.
   * @type {String}
   */
  platform: defaultValue('platform', Channel.DesktopMobile),

  isNative: configTracking?.platform === Channel.Android || configTracking?.platform === Channel.iOS,

  /**
   * @type {boolean}
   */
  debug: defaultValue('debug', null),
  /**
   * @type {boolean}
   */
  unitTest: defaultValue('unitTest', false),
  /**
   * @type {Object}
   */
  custom: defaultValue('setup', null),
  /**
   * @type {Array}
   */
  plugins: defaultValue('plugins', null),
  /**
   * @type {any[]}
   */
  dataLayer: window[defaultValue('dataLayer', 'dataLayer')],
  /**
   * @type {any[]}
   */
  dataLayerDelayed: window[defaultValue('dataLayerDelayed', 'dataLayerDelayed')],

  /**
   * @type {boolean}
   */
  disablePropertyPropagation: defaultValue('disablePropertyPropagation', false),
  /**
   * @type {boolean}
   */
  disableDefaultTrackPage: defaultValue('disableDefaultTrackPage', false),
  /**
   * @type {boolean}
   */
  debugEnableLogging: defaultValue('debugEnableLogging', false),
  /**
   * @type {number}
   */
  debugLogPercentage: defaultValue('debugLogPercentage', 0.01),
  /**
   * @type {Number | String}
   */
  userId: defaultValue('userId', null),

  /**
   * @type {Function | null}
   */
  getEprivacySDK: defaultValue('getEprivacySDK', null),

  /**
   * @type {boolean}
   */
  asyncSurveyStyles: defaultValue('asyncSurveyStyles', false),

  /**
   * @type {boolean}
   */
  useBeaconAPI: defaultValue('useBeaconAPI', false),

  /**
   * @type {string}
   */
  surveyTheme: defaultValue('surveyTheme', ''),

  /// Shared configs (not coming from `window.configTracking`)

  laquesisQa: defaultValue('laquesisQa', null),
  /**
   * This is different from `laquesisQa` - it shows if the SDK is in QA mode, eg. when using playground
   * @type {boolean}
   */
  qaMode: false,
  currentSessionLong: null,
  currentSession: null,
  cookieFromLq: false,
  listOfActiveSurveys: [],

  // Link events
  linkCallBack: null,
  linkCount: 0,
  linkTotalCount: 0,
  linkSetTimeout: null,
  linkEventName: null,
  trackerList: null,
  pluginList: null,
};

export function getNinjaConfig() {
  return ninjaConfig;
}
