import { LAQUESIS_SURVEY_JS_URL } from '../../../const';
import { loadScript } from '../../../utils';

export let isLoaded = false;

export function loadSurveyScript() {
  return new window.Promise(resolve => {
    if (isLoaded) {
      resolve();
    } else {
      loadScript(LAQUESIS_SURVEY_JS_URL, 'laquesisSurvey', () => {
        isLoaded = true;
        resolve();
      });
    }
  });
}
